import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Input from 'reusecore/src/elements/Input';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import ContactFormWrapper, { ContactForm, SectionHeader } from './contactForm.style';

const ContactFormSection = ({ sectionHeader, sectionTitle, btnStyle }) => {
    const [status, setStatus] = useState({
        submitted: false,
        submitting: false,
        info: { error: false, msg: null },
    });
    const [inputs, setInputs] = useState({
        email: null,
        message: null,
    });
    const handleServerResponse = (ok, msg) => {
        if (ok) {
            setStatus({
                submitted: true,
                submitting: false,
                info: { error: false, msg: msg },
            });
            setInputs({
                email: '',
                message: '',
            });
        } else {
            setStatus({
                info: { error: true, msg: msg },
            });
        }
    };
    const handleOnChange = (text, e) => {
        e.persist();
        setInputs((prev) => ({
            ...prev,
            [e.target.id]: text,
        }));
        setStatus({
            submitted: false,
            submitting: false,
            info: { error: false, msg: null },
        });
    };
    const handleOnSubmit = (e) => {
        e.preventDefault();
        setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
        axios({
            method: 'POST',
            url: 'https://formspree.io/xbjabdpy',
            data: inputs,
        })
            .then((response) => {
                handleServerResponse(
                    true,
                    'Obrigado, sua mensagem foi encaminhada. Entraremos em contato com você em breve!'
                );
            })
            .catch((error) => {
                handleServerResponse(false, error.response.data.error);
            });
    };
    return (
        <ContactFormWrapper id='contactFormSection'>
            <Container>
                <Box {...sectionHeader}>
                    <Heading content='Fale Conosco' {...sectionTitle} />
                </Box>
                <Box>
                    <ContactForm onSubmit={handleOnSubmit}>
                        <Input
                            label='Email'
                            inputType='email'
                            isMaterial={false}
                            placeholder='Email Address'
                            name='_replyto'
                            aria-label='email'
                            id='email'
                            onChange={handleOnChange}
                            required
                            value={inputs.email}
                        />
                        <Input
                            label='Mensagem'
                            id='message'
                            inputType='textarea'
                            isMaterial={false}
                            placeholder='Mensagem...'
                            name='message'
                            onChange={handleOnChange}
                            required
                            value={inputs.message}
                        />
                        <Button
                            type='submit'
                            title={
                                !status.submitting
                                    ? !status.submitted
                                        ? 'Encaminhar'
                                        : 'Encaminhado'
                                    : 'Encaminhando...'
                            }
                            {...btnStyle}
                        />
                    </ContactForm>
                    {status.info.error && (
                        <Fade up>
                            <Heading className='error' as='h5' content={status.info.msg} />
                        </Fade>
                    )}
                    {!status.info.error && status.info.msg && (
                        <SectionHeader>
                            <Fade up>
                                <Heading as='h5' content={status.info.msg} />
                            </Fade>
                        </SectionHeader>
                    )}
                </Box>
            </Container>
        </ContactFormWrapper>
    );
};

// NewsletterSection style props
ContactFormSection.propTypes = {
    sectionHeader: PropTypes.object,
    sectionTitle: PropTypes.object,
    sectionSubTitle: PropTypes.object,
};

// NewsletterSection default style
ContactFormSection.defaultProps = {
    // section header default style
    sectionHeader: {
        mb: '56px',
    },
    // section title default style
    sectionTitle: {
        textAlign: 'center',
        fontSize: ['20px', '24px'],
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '0',
    },
    // button default style
    btnStyle: {
        minWidth: '152px',
        minHeight: '45px',
        fontSize: '14px',
        fontWeight: '500',
    },
};

export default ContactFormSection;
