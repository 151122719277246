import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import ImageGatsby from 'reusecore/src/elements/ImageGatsby';
import CustomerWrapper, { ImageWrapper } from './customer.style';

const Customer = () => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                client {
                    id
                    title
                    image {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 400) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `);
    const { client } = data.appClassicJson;

    return (
        <CustomerWrapper>
            <Text content='Alguns de nossos clientes:' />
            <ImageWrapper>
                {client.map((item) => (
                    <ImageGatsby
                        key={`client-key${item.id}`}
                        alt={item.title}
                        style={{ width: '150px', margin: '20px 12px 20px 20px', overflow: 'visible' }}
                        fluid={item.image.childImageSharp.fluid}
                        src={item.image.publicURL}
                        useLazy={true}
                    />
                ))}
            </ImageWrapper>
        </CustomerWrapper>
    );
};

export default Customer;
