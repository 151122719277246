import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from 'reusecore/src/elements/UI/Logo';
import Container from 'common/src/components/UI/Container';
import FooterArea, { MenuArea, Menu, MenuItem, CopyrightText } from './footer.style';

const Footer = ({ notMain }) => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                footer {
                    logo {
                        publicURL
                    }
                    menu {
                        id
                        link
                        text
                    }
                    politicas {
                        id
                        link
                        text
                    }
                    widgets {
                        id
                        icon {
                            publicURL
                        }
                        title
                        description
                    }
                }
            }
        }
    `);
    const { logo, menu, politicas } = data.appClassicJson.footer;

    const date = new Date();
    const year = date.getFullYear();

    return (
        <FooterArea>
            <Container>
                {/* <WidgetArea>
          {widgets.map(item => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea> */}
                {/* End of footer widgets area */}
                <MenuArea>
                    <Logo notMain={notMain} className='logo' logoSrc={logo.publicURL} title='App Classic' />
                    <Menu>
                        {menu.map((item) => (
                            <MenuItem key={`footer-link${item.id}`}>
                                <AnchorLink text={item.text} href={item.link} offset={84}></AnchorLink>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Menu>
                        {politicas.map((item) => (
                            <MenuItem key={`footer-link${item.id}`}>
                                <a text={item.text} href={item.link} offset={84} target={'_blank'}></a>
                            </MenuItem>
                        ))}
                    </Menu>
                    <CopyrightText>Copyright {year} By Akrual</CopyrightText>
                </MenuArea>
                {/* End of footer menu area */}
            </Container>
        </FooterArea>
    );
};

export default Footer;
