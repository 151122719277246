const colors = {
    transparent: 'transparent',
    labelColor: '#767676',
    lightBorder: '#f1f4f6',
    inactiveField: '#F7F8FB',
    inactiveButton: '#b7dbdd',
    inactiveIcon: '#EBEBEB',
    primaryHover: '#3C74FF',
    secondary: '#09142E',
    secondaryHover: '#112045',
    secondaryHoverLight: '#24428f',
    yellow: '#FFA740',
    yellowHover: '#F6C416',
    borderColor: '#E8EBF1',
    black: '#000000',
    white: '#ffffff',
    light: '#FAFBFF',
    gray: '#E4E4E4',
    primary: '#2563FF',
    error: '#ff4247',
    headingColor: '#0F2137',
    quoteText: '#343D48',
    menu: '#0D233E',
    textColor: 'rgba(52, 61, 72, 0.8)',
    linkColor: '#2b9eff',
    shadow: 'rgba(38, 78, 118, 0.1)',
};

export default colors;
