import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';
import Image from '../Image';

const ImageWrapper = styled(Img)(
    {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
    },
    base,
    themed('Image')
);

const ImageGatsby = ({ fluid, alt, src, useLazy, ...props }) => {
    return useLazy ? <ImageWrapper fluid={fluid} alt={alt} {...props} /> : <Image src={src} alt={alt} {...props} />;
};

export default ImageGatsby;

ImageGatsby.propTypes = {
    fluid: PropTypes.any,
    alt: PropTypes.string.isRequired,
    src: PropTypes.string,
    useLazy: PropTypes.bool,
};

ImageGatsby.defaultProps = {
    m: 0,
    useLazy: false,
};
