import styled from 'styled-components';

const CustomerWrapper = styled.div`
    max-width: 85%;
    padding: 37px 0;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }

    p {
        color: #6d7279;
        font-size: 16px;
        line-height: 29px;
        margin: 0 12px 0 0;
        @media only screen and (max-width: 991px) {
            margin: 0 0 20px 0;
        }
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    img {
        margin: 0 12px;
        @media only screen and (max-width: 667px) {
            margin: 5px 10px;
        }
    }
`;

export default CustomerWrapper;
