import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import ImageGatsby from 'reusecore/src/elements/ImageGatsby';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './designedAndBuilt.style';

const DesignedAndBuilt = () => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                designAndBuilt {
                    image {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    title
                    description
                }
            }
        }
    `);
    const { image, title, description } = data.appClassicJson.designAndBuilt;

    return (
        <SectionWrapper>
            <Container>
                <ContentWrapper>
                    <div className='image'>
                        <ImageGatsby
                            fluid={image.childImageSharp.fluid}
                            src={image.publicURL}
                            alt='Built Logo'
                            style={{ width: 794 }}
                            useLazy={true}
                        />
                    </div>
                    <div className='content'>
                        <Heading content={title} />
                        <Text content={description} />
                        <Button title='Saiba Mais' href={'/software-securitizacao'} />
                    </div>
                </ContentWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default DesignedAndBuilt;
