import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import SectionWrapper, { FeatureWrapper } from './keyNumbers.style';

const KeyNumbers = () => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                keyNumbers {
                    numbers {
                        id
                        color
                        icon {
                            publicURL
                        }
                        number
                        title
                    }
                }
            }
        }
    `);
    const { numbers } = data.appClassicJson.keyNumbers;
    return (
        <SectionWrapper id='KeyNumbers'>
            <Container>
                <FeatureWrapper>
                    {numbers.map((item) => (
                        <Fade up delay={50 * item.id} key={`key-feature--key${item.id}`}>
                            <FeatureBlock
                                style={{ '--color': `${item.color}` }}
                                icon={
                                    <Fragment>
                                        <Icon className='plus' icon={plus} />
                                        <Icon className='circle' icon={starOutline} />
                                        <Image src={item.icon.publicURL} alt={item.title} />
                                    </Fragment>
                                }
                                title={<Heading as='h2' content={item.number} lineHeight={0} />}
                                description={<Text content={item.title} />}
                                iconStyle={{ height: 58, width: 45 }}
                            />
                        </Fade>
                    ))}
                </FeatureWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default KeyNumbers;
