import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import ImageGatsby from 'reusecore/src/elements/ImageGatsby';
import Container from 'common/src/components/UI/Container';
import Rating from 'common/src/components/Rating';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { CarouselWrapper } from './testimonial.style';

const Testimonial = () => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                testimonial {
                    slogan
                    title
                    reviews {
                        id
                        title
                        description
                        avatar {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 185) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        name
                        designation
                        review
                    }
                }
            }
        }
    `);

    const { slogan, title, reviews } = data.appClassicJson.testimonial;

    const glideOptions = {
        type: 'carousel',
        gap: 0,
        autoplay: 10000,
        perView: 2,
        animationDuration: 700,
        breakpoints: {
            728: { perView: 1 },
            1200: { perView: 2 },
        },
    };

    return (
        <SectionWrapper id='testimonial'>
            <Container>
                <SectionHeader style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    <Fade up>
                        <Heading content={title} />
                        <Heading as='h5' content={slogan} />
                    </Fade>
                </SectionHeader>
                <CarouselWrapper>
                    <Fade up delay={100}>
                        <GlideCarousel
                            options={glideOptions}
                            nextButton={
                                <Button icon={<i className='flaticon-next' />} aria-label='Next' variant='fab' />
                            }
                            prevButton={
                                <Button icon={<i className='flaticon-left-arrow' />} aria-label='Prev' variant='fab' />
                            }
                        >
                            <Fragment>
                                {reviews.map((item) => (
                                    <GlideSlide key={`testimonial--key${item.id}`}>
                                        <div className='review-card'>
                                            <Heading as='h3' content={item.title} />
                                            <Text content={item.description} />
                                            <div className='card-footer'>
                                                <div className='image'>
                                                    <ImageGatsby
                                                        fluid={item.avatar.childImageSharp.fluid}
                                                        src={item.avatar.publicURL}
                                                        alt={'Testimonial do ' + item.name}
                                                        style={{ width: 80, height: 80 }}
                                                        useLazy={true}
                                                    />
                                                </div>
                                                <div className='reviewer-info'>
                                                    <div className='content'>
                                                        <Heading as='h4' content={item.name} />
                                                        <Text content={item.designation} />
                                                    </div>
                                                    <Rating rating={item.review} />
                                                </div>
                                            </div>
                                        </div>
                                    </GlideSlide>
                                ))}
                            </Fragment>
                        </GlideCarousel>
                    </Fade>
                </CarouselWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default Testimonial;
